import axios from '@/plugins/axios'
import { mapActions, mapGetters } from 'vuex'
export default {
    data() {
        return {
            tKey: 'members.',
            loading: false,    
            kpis: [],        
        }
    },
    computed: {
        ...mapGetters('members', ['members'])
    },
    methods: {
        ...mapActions('members', ['setMembers']),
        async refreshAll() {
            Promise.all([await this.refreshMembersData(), await this.refreshKPIData()])
                .then(() => {})
        },
        async refreshMembersData() {
            this.loading = 'allMembers'
            Promise.all([
                await this.getMembers(),               
            ])
                .then((res) => {
                    this.setMembers(res[0].data)
                })
                .finally(() => {
                    this.loading = false
                })
        },
        async refreshKPIData() {
            this.loading = 'KPIs'
            Promise.all([
                await this.getFlagsKPI(),
                await this.getServicesTypesKPI(),
                await this.getHeatingSystemsKPI(),
                await this.getBoilerSystemsKPI(),
                await this.getStarsKPI(),
            ])            
                .then((res) => {                                    
                    const flagsKPI = {
                        label: 'qualityFlags',
                        icon: 'flag',
                        values: res[0].data,
                    }
                    flagsKPI.chart = {
                        options: {
                            chart: {
                                type: 'pie',
                            },
                            legend: {
                                position: "bottom",
                            },
                            labels: flagsKPI.values.map((v) => this.translateLabels(flagsKPI.label, v.type)) || 'null',
                        },
                        series: flagsKPI.values.map((v) => v.count),
                    }
                    const servicesTypesKPI = {
                        label: 'servicesTypes',
                        icon: 'concierge-bell',
                        values: res[1].data,
                        noTranslate: true,
                    }
                    servicesTypesKPI.chart = {
                        options: {
                            chart: {
                                type: 'pie',
                            },
                            legend: {
                                position: "bottom",
                            },
                            labels: this.combineSmallerLabels(servicesTypesKPI.values.map((v) => v.count), servicesTypesKPI.values.map((v) => v.type)),
                        },
                        series: this.combineSmallerSeries(servicesTypesKPI.values.map((v) => v.count)),
                    }
                    const heatingSystemsKPI = {
                        label: 'heatingSystems',
                        icon: 'fire',
                        values: res[2].data,
                    }
                    heatingSystemsKPI.chartSystem = {
                        options: {
                            chart: {
                                type: 'pie',
                            },
                            legend: {
                                position: "bottom",
                            },
                            labels: this.combineSmallerLabels(heatingSystemsKPI.values.systems.map((v) => v.count), heatingSystemsKPI.values.systems.map((v) => this.translateLabels(heatingSystemsKPI.label, v.type)), 5),
                        },
                        series: this.combineSmallerSeries(heatingSystemsKPI.values.systems.map((v) => v.count), 5),
                    }
                    heatingSystemsKPI.chartSource = {
                        options: {
                            chart: {
                                type: 'pie',
                            },
                            legend: {
                                position: "bottom",
                            },
                            labels: this.combineSmallerLabels(heatingSystemsKPI.values.sources.map((v) => v.count), heatingSystemsKPI.values.sources.map((v) => this.translateLabels('sources', v.type)), 5),
                        },
                        series: this.combineSmallerSeries(heatingSystemsKPI.values.sources.map((v) => v.count), 5),
                    }
                    const boilerSystemsKPI = {
                        label: 'boilerSystems',
                        icon: 'tint',
                        values: res[3].data,
                    }
                    boilerSystemsKPI.chartSystem = {
                        options: {
                            chart: {
                                type: 'pie',
                            },
                            legend: {
                                position: "bottom",
                            },
                            labels: this.combineSmallerLabels(boilerSystemsKPI.values.systems.map((v) => v.count), boilerSystemsKPI.values.systems.map((v) => this.translateLabels(boilerSystemsKPI.label, v.type)), 5),
                        },
                        series: this.combineSmallerSeries(boilerSystemsKPI.values.systems.map((v) => v.count), 5),
                    }
                    boilerSystemsKPI.chartSource = {
                        options: {
                            chart: {
                                type: 'pie',
                            },
                            legend: {
                                position: "bottom",
                            },
                            labels: this.combineSmallerLabels(boilerSystemsKPI.values.sources.map((v) => v.count), boilerSystemsKPI.values.sources.map((v) => this.translateLabels('sources', v.type)), 5),
                        },
                        series: this.combineSmallerSeries(boilerSystemsKPI.values.sources.map((v) => v.count), 5),
                    }
                    const starsKPI = {
                        label: 'stars',
                        icon: 'star',
                        values: res[4].data,
                    }    
                    starsKPI.chart = {
                        options: {
                            chart: {
                                type: 'pie',
                            },
                            legend: {
                                position: "bottom",
                            },
                            labels: starsKPI.values.map((v) => this.translateLabels(starsKPI.label, v.type)),
                        },
                        series: starsKPI.values.map((v) => v.count),
                    }
                    this.setKPIs(starsKPI, servicesTypesKPI, heatingSystemsKPI, boilerSystemsKPI, flagsKPI)
                })
                .finally(() => {
                    this.loading = false
                })
        },
        async getMembers() {
            return await axios.post('api/AHV/getMembers', { membersOnly: true })
        },
        async getFlagsKPI() {
            return await axios.post('api/AHV/getFlagsKPI', { membersOnly: true })
        },
        async getServicesTypesKPI() {
            return await axios.post('api/AHV/getServicesTypesKPI', { membersOnly: true })
        },
        async getHeatingSystemsKPI() {
            return await axios.post('api/AHV/getHeatingSystemsKPI', { membersOnly: true })
        },
        async getBoilerSystemsKPI() {
            return await axios.post('api/AHV/getBoilerSystemsKPI', { membersOnly: true })
        },
        async getStarsKPI() {
            return await axios.post('api/AHV/getStarsKPI', { membersOnly: true })
        },
        setKPIs(...kpis) {
            this.kpis = []
            for(const k of kpis)
                this.kpis.push(k)    
        },
        combineSmallerSeries(series, top = 3) {
            let processedSeries = series.slice(0, top)
            const otherValues = series.slice(top)
            if (otherValues.length > 0) {
                const sumOfOtherValues = otherValues.reduce((acc, value) => acc + value, 0)
                processedSeries.push(sumOfOtherValues)
            }
            return processedSeries
        },
        combineSmallerLabels(series, labels, top = 3) {
            const processedLabels = labels.slice(0, top)
            if (!series.slice(top).length) return labels
            processedLabels.push(this.$t('utils.others'))      
            return processedLabels
        },
        translateLabels(label, type) {
            return this.$t(`kpi.${label}.${type.replace("'", "")}`)
        },
    },
}