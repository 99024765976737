<template>
  <div id="members">
    <loading v-if="loading" />
    <div v-else>
      <div id="viewNavigator">
        <span class="navigator-btn" @click="showMap = true" :class="showMap && 'active'" v-html="$t(`${tKey}showMap`)" />
        <span class="navigator-btn" @click="showMap = false" :class="!showMap && 'active'" v-html="$t(`${tKey}showIndicators`)" />
      </div>
      <div v-if="showMap">
        <search-bar v-if="isLoggedIn"
          class="w-100" 
          :placeholderKey="`${tKey}search`"
          @search="(query) => searchQuery = query"
        >
          <div class="d-flex align-items-center h-100 bg-secondary text-white">
            <b-checkbox class="my-auto mx-2" v-model="noLocationOnly">
              <span v-html="$t(`${tKey}noLocationOnly`)" />
            </b-checkbox>
          </div>
        </search-bar>
        <ItemsList v-if="isLoggedIn" :tKey="tKey" :items="searchedMembers" :isFromMembers="true" />
        <l-map v-if="members && members.length"
          ref="myMap"
          style="height: 700px;"
          :center="center"
          :zoom="zoom"
          :options="mapOptions"
          @update:center="centerUpdate"
          @update:zoom="zoomUpdate"
          @update:bounds="boundsUpdate"
        >
          <l-tile-layer
            :url="url"
            :attribution="attribution"
          />
          <l-control class="d-flex flex-column" position="topright">          
            <div class="bg-white p-2 m-1 shadow">
              <h6 v-html="$t(`${tKey}legend.header`)" />
              <div class="d-flex align-items-center flex-column">     
                <b-row class="w-100 px-2 align-items-center" no-gutters>
                  <b-col sm="3">
                    <ahv-logo :height="27" />
                  </b-col>
                  <b-col class="d-flex flex-column">
                    <span v-html="$t(`${tKey}legend.totalMembers`)" />
                    <span class="font-weight-bold" v-html="longLatMembers.length" />
                  </b-col>
                </b-row>
                <b-row class="w-100 px-2 align-items-center" no-gutters>
                  <b-col sm="3">
                    <font-awesome-layers>
                      <font-awesome-icon icon="map-marker-alt" size="2x" color="lightgrey" transform="right-4" />
                      <font-awesome-icon icon="slash" size="2x" transform="shrink-2" />
                    </font-awesome-layers>
                  </b-col>
                  <b-col class="d-flex flex-column">
                    <span v-html="$t(`${tKey}legend.missingMembers`)" />
                    <span class="font-weight-bold" v-html="searchedMembers.length - longLatMembers.length" />
                  </b-col>
                </b-row>                
                <div class="mt-2 d-flex flex-column">
                  <b-button variant="info" size="sm" @click="refreshMembersData">
                    <font-awesome-icon class="mr-2" icon="sync-alt" />
                    <span v-html="$t(`${tKey}refresh`)" />
                  </b-button>
                  <span class="font-italic" v-html="$t(`${tKey}refreshInfo`)" />
                </div>
              </div>
            </div>
          </l-control>
          <l-circle-marker v-for="member in searchedMembers" :key="member.idMember"
            :radius="6"
            :lat-lng="[member.lat, member.long]"
            :color="colors.members"
            :fill-color="colors.members"
          >  
            <l-popup class="custom-popup">
              <div v-if="member.qualityFlag !== 'ok'" 
                class="flag-container p-1 mb-2" 
                :style="`background-color: ${getFlagColor(member.qualityFlag)}`"
                v-html="$t(`flags.${member.qualityFlag}`)"
              />
              <h6 class="d-flex justify-content-between font-weight-bold align-items-center pb-2">
                <span v-html="member.name" />
                <ahv-logo :height="20" />
              </h6>
              <div class="border-top pt-2">
                <p v-html="`${member.address}${checkIfBothAddressAndCity(member)}<b>${member.city}</b>`" />                
              </div>
              <b-link v-if="isLoggedIn" :to="$i18nRoute({ name: 'MemberDetails', params: { idMember: member.idMember }})">
                <span v-html="$t(tKey + 'display')" />
                <font-awesome-icon class="ml-2" icon="arrow-right"/>
              </b-link>
            </l-popup>
          </l-circle-marker>
        </l-map>      
      </div>
      <b-row v-else id="kpis" cols="1">
        <b-col sm="12" class="pt-4" v-for="kpi in kpis.filter(k => k.values.length)" :key="kpi.label">
          <b-card class="h-100" no-body>
            <b-card-header class="bg-white text-colorbase m-0 d-flex justify-content-between align-items-center" header-tag="h5">
              <span v-html="$t(`kpi.${kpi.label}.header`)" />
              <font-awesome-icon :icon="kpi.icon" />
            </b-card-header>
            <b-card-body>
              <div class="my-2" v-html="$t(`kpi.${kpi.label}.description`)" />	
              <div class="d-flex justify-content-between">
                <div v-if="kpi.values.length">
                  <div class="value-container my-2" v-for="val in kpi.values" :key="val.type">
                    <div class="font-weight-bold kpi-label-header" v-html="kpi.noTranslate ? val.type : translateLabels(kpi.label, val.type)" />
                    <div>
                      <span class="font-weight-bold" v-html="val.count" />
                      <span class="small ml-1" v-html="$tc('utils.establishment', val.count)" />
                    </div>
                  </div>
                </div>
                <apexchart v-if="kpi.chart" type="pie" :options="kpi.chart.options" :series="kpi.chart.series" />
              </div>              
            </b-card-body>
          </b-card>
        </b-col> 
        <b-col sm="12" class="pt-4" v-for="kpi in kpis.filter(k => !k.values.length)" :key="kpi.label">
          <b-card no-body>
            <b-card-header class="bg-white text-colorbase m-0 d-flex justify-content-between align-items-center" header-tag="h5">
              <span v-html="$t(`kpi.${kpi.label}.header`)" />
              <font-awesome-icon :icon="kpi.icon" />
            </b-card-header>
            <b-card-body>
              <div v-html="$t(`kpi.${kpi.label}.description`)" />
              <b-row class="pb-2 border-bottom border-grey" no-gutters>      
                <b-col>
                  <b-row cols="2" v-if="kpi.values.systems.length">
                    <b-col class="value-container my-2" v-for="val in kpi.values.systems" :key="val.type">
                      <div class="font-weight-bold kpi-label-header" v-html="kpi.noTranslate ? val.type : translateLabels(kpi.label, val.type)" />
                      <div>
                        <span class="font-weight-bold" v-html="val.count" />
                        <span class="small ml-1" v-html="$tc('utils.establishment', val.count)" />
                      </div>
                    </b-col>
                  </b-row>
                </b-col>          
                <b-col md="12" lg="4">
                  <apexchart v-if="kpi.chartSystem" type="pie" :options="kpi.chartSystem.options" :series="kpi.chartSystem.series" />
                </b-col>
              </b-row>
              <div class="pt-2" v-html="$t(`kpi.sources.description`)" />
              <b-row no-gutters>        
                <b-col>
                  <b-row cols="2" v-if="kpi.values.sources.length">
                    <b-col class="value-container my-2" v-for="val in kpi.values.sources" :key="val.type">
                      <div class="font-weight-bold kpi-label-header" v-html="kpi.noTranslate ? val.type : translateLabels('sources', val.type)" />
                      <div>
                        <span class="font-weight-bold" v-html="val.count" />
                        <span class="small ml-1" v-html="$tc('utils.establishment', val.count)" />
                      </div>
                    </b-col>
                  </b-row>
                </b-col>        
                <b-col md="12" lg="4">
                  <apexchart v-if="kpi.chartSource" type="pie" :options="kpi.chartSource.options" :series="kpi.chartSource.series" />
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
        </b-col>     
      </b-row>
    </div>
  </div>
</template>

<script>
import { latLng } from 'leaflet'
import MembersMixin from '@/mixins/api/members'
import AuthMixin from "@/mixins/api/auth"
import ItemsList from '@/components/utils/ItemsList'
export default {
    name: 'Members',
    components: {
      ItemsList
    },
    mixins: [MembersMixin, AuthMixin],
    data() {
    return {
      zoom: 9.5,
      center: latLng(46.2307967, 7.7220283),
      bounds: {
        _northEast: latLng(46.92588289494367, 9.014282226562502),
        _southWest: latLng(45.77135470445038, 6.231994628906251)
      },
      colors: {
        members: '#DA291C',
        notMembers: 'cornflowerblue',
      },
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution:
        '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      currentZoom: null,
      currentCenter: null,
      currentBounds: null,
      mapOptions: {
        zoomSnap: 0.5,
        zoomControl: false,
      },
      showMap: true,
      searchQuery: null,
      noLocationOnly: false,
    }
  },
  computed: {
    longLatMembers() {
      const results = this.searchedMembers.filter(o => o.lat != null && o.long != null)
      return results.filter((obj, index, self) => index === self.findIndex(o => o.idMember === obj.idMember))
    },
    searchedMembers() {
      let results = this.members.filter((obj, index, self) => index === self.findIndex(o => o.idMember === obj.idMember))
      if(this.noLocationOnly) results = results.filter(o => o.lat == null || o.long == null)
      if(this.searchQuery == null) return results
      return results.filter(o => o.name.toLowerCase().includes(this.searchQuery.toLowerCase()))
    },
  },
  mounted() {
    if(this.members == null || !this.members.length || this.members.length == 0)
      this.refreshAll()
    else this.refreshKPIData()
  },
  methods: {
    getFlagColor(flagTxt) {
      switch(flagTxt) {
        case "ok": return 'lightgreen'
        default: return 'darkorange'
      }
    },
    checkIfBothAddressAndCity(obj) {
      if(obj.city != null && obj.address != null && obj.city != '' && obj.address != '')     
        return ', '
      return ''
    },
    zoomUpdate(zoom) {
      this.currentZoom = zoom
    },
    centerUpdate(center) {
      this.currentCenter = center
    },
    boundsUpdate(bounds) {
      this.currentBounds = bounds
    },
  },
}
</script>

<style lang="scss">
#members {
  & ::-webkit-scrollbar {
    width: 6px;
  }

  & ::-webkit-scrollbar-track {
    background: theme-color-level("custom", -10);
  }

  & ::-webkit-scrollbar-thumb {
    background: theme-color("custom");
  }

  & ::-webkit-scrollbar-thumb:hover {
    background: theme-color-level("custom", 4);
  }

  .custom-popup {

    p, h6 {
      margin: 0;
    }

    .flag-container {
      font-size: 1em;
      text-align: center;
      color: white;
      border-radius: 5px;
    }
  }

  #kpis {
    .value-container {
      line-height: 1.2;
    }
    .chart-container {
      width: 33% !important;
      flex-grow: 1;
    }
    .kpi-label-header {
      line-height: 1;
    }
  }
}

#viewNavigator {
  width: 100%;
  text-align: center;
  margin: 1em 0;

  .navigator-btn {
    padding: 0.375rem 0.75rem;
    color: $colorbase-grey;
    border: 2px solid $colorbase-grey;
    border-radius: 0 50px 50px 0;
    background-color: $colorbase-lightergrey;
    width: 15em !important;
    min-width: 15em !important;
    cursor: pointer;

    &:first-child {
      border-radius: 50px 0 0 50px;
      border-right: 1px solid $colorbase-grey;
    }

    &:last-child {
      border-left: 1px solid $colorbase-grey;
    }

    &.active {
      color: $colorbase;
      background-color: $colorbase-lightest;
      border-color: $colorbase;
    }
  }
}
</style>